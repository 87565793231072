<template>
  <section class="container">
    <h1>ログイン</h1>
    <transition>
      <router-view/>
    </transition>
  </section>
</template>

<script>
// import firebase from 'firebase/app'
import {Mixin} from '@/mixins/common'
import { mapGetters } from 'vuex';

export default {
  created () {
    console.log(this.LoginLoading);
  },
  beforeCreate() {
    // firebase.auth().onAuthStateChanged((user) => {
    //  if (user) {
    //    console.log('login!!');
    //  }
    //  this.loading = false
    //})
  },
  computed: {
    ...mapGetters([
      'LoginLoading'
    ])
  },
  mounted () {
    this.loginHandler('/mypage')
  },
  mixins:[Mixin],
  methods: {
    googleLogin: function() {
      // const provider = new firebase.auth.GoogleAuthProvider()

      // firebase.auth().signInWithRedirect(provider)
    }
  }
}
</script>

<style scoped>
section{
  background: pink;
}
</style>
